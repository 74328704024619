import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../framework/src/BlockComponent";
import CustomTextItem from "./CustomTextItem";
import NavigationBlock from "../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../blocks/adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../blocks/adapters/src/InfoPageAdapter';

// Customizable Area Start
import PrivacyPolicyAdapter from "../../blocks/adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../blocks/adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../blocks/adapters/src/SplashScreenAdapter";

//Assembler generated adapters start

//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});

interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface S { }

interface SS { }

class HomeScreen extends BlockComponent<Props, S, SS> {

  static instance:HomeScreen;

  constructor(props: Props) {
    super(props);
    HomeScreen.instance = this;
  }

  render() {
    const { navigation } = this.props;
    const _this = this;

    return (
      <SafeAreaView>
        <ScrollView contentContainerStyle={styles.scrollView} bounces={false}>
          <View style={styles.container}>
            <View style={styles.header}>
              <Text style={styles.welcome}>
                Welcome to AutoBxEngPro06286958!
              </Text>
            </View>

            <Text style={styles.instructions}>{instructions}</Text>
            <Text style={styles.header}>DEFAULT BLOCKS</Text>
            <CustomTextItem
              content={'InfoPage'}
              onPress={() => navigation.navigate("InfoPage")}
            />
            <CustomTextItem
              content={'Alert'}
              onPress={() => this.showAlert("Example", "This happened")}
            />
<CustomTextItem content={'locallistingads'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'core'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'utilities'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'outlookintegration'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'organisationhierarchy'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'SpellCheck'}  onPress={() => navigation.navigate("SpellCheck")} />
<CustomTextItem content={'ContactList'}  onPress={() => navigation.navigate("ContactList")} />
<CustomTextItem content={'customkeyboard'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Tasks'}  onPress={() => navigation.navigate("Tasks")} />
<CustomTextItem content={'AccountGroups'}  onPress={() => navigation.navigate("AccountGroups")} />
<CustomTextItem content={'Upvotedownvote'}  onPress={() => navigation.navigate("Upvotedownvote")} />
<CustomTextItem content={'PhotoLibrary'}  onPress={() => navigation.navigate("PhotoLibrary")} />
<CustomTextItem content={'nearbyfriends'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'elasticsearch'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'mentionstagging'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'fileattachment'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'referrals'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'feedbackcollection'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'livestreaming'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'emergencysos'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'BulkUploading'}  onPress={() => navigation.navigate("BulkUploading")} />
<CustomTextItem content={'GroupVideoCall'}  onPress={() => navigation.navigate("GroupVideoCall")} />
<CustomTextItem content={'ActivityFeed'}  onPress={() => navigation.navigate("ActivityFeed")} />
<CustomTextItem content={'TermsConditions'}  onPress={() => navigation.navigate("TermsConditions")} />
<CustomTextItem content={'Gamification'}  onPress={() => navigation.navigate("Gamification")} />
<CustomTextItem content={'PerformanceTracker'}  onPress={() => navigation.navigate("PerformanceTracker")} />
<CustomTextItem content={'AccountTransfer'}  onPress={() => navigation.navigate("AccountTransfer")} />
<CustomTextItem content={'FacialTracking'}  onPress={() => navigation.navigate("FacialTracking")} />
<CustomTextItem content={'VideoTrimmer'}  onPress={() => navigation.navigate("VideoTrimmer")} />
<CustomTextItem content={'OrderManagement'}  onPress={() => navigation.navigate("OrderManagement")} />
<CustomTextItem content={'Catalogue'}  onPress={() => navigation.navigate("Catalogue")} />
<CustomTextItem content={'Favourites'}  onPress={() => navigation.navigate("Favourites")} />
<CustomTextItem content={'CallRecording'}  onPress={() => navigation.navigate("CallRecording")} />
<CustomTextItem content={'Followers'}  onPress={() => navigation.navigate("Followers")} />
<CustomTextItem content={'privacysettings'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'leaderboard'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'audiolibrary'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'InvoiceBilling'}  onPress={() => navigation.navigate("InvoiceBilling")} />
<CustomTextItem content={'contentflag'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'addfriends'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'scoring'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'LanguageSupport'}  onPress={() => navigation.navigate("LanguageSupport")} />
<CustomTextItem content={'LanguageOptions'}  onPress={() => navigation.navigate("LanguageOptions")} />
<CustomTextItem content={'LinkShare'}  onPress={() => navigation.navigate("LinkShare")} />
<CustomTextItem content={'DownloadOptions'}  onPress={() => navigation.navigate("DownloadOptions")} />
<CustomTextItem content={'Hashtags'}  onPress={() => navigation.navigate("Hashtags")} />
<CustomTextItem content={'ApplePayIntegration'}  onPress={() => navigation.navigate("ApplePayIntegration")} />
<CustomTextItem content={'AutomaticFormCreation'}  onPress={() => navigation.navigate("AutomaticFormCreation")} />
<CustomTextItem content={'PricingEngine'}  onPress={() => navigation.navigate("PricingEngine")} />
<CustomTextItem content={'Trending'}  onPress={() => navigation.navigate("Trending")} />
<CustomTextItem content={'timetracker'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'recommendationengine'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'digitalsignatures'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'EventRegistration'}  onPress={() => navigation.navigate("EventRegistration")} />
<CustomTextItem content={'storecredits'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'DailyScheduleNotifications'}  onPress={() => navigation.navigate("DailyScheduleNotifications")} />
<CustomTextItem content={'CustomerDialpadKeyboard'}  onPress={() => navigation.navigate("CustomerDialpadKeyboard")} />
<CustomTextItem content={'fullteamvisibility'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'InventoryManagement'}  onPress={() => navigation.navigate("InventoryManagement")} />
<CustomTextItem content={'deliveryestimator'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'QuestionBank'}  onPress={() => navigation.navigate("QuestionBank")} />
<CustomTextItem content={'AutomaticCheckoutCalculation'}  onPress={() => navigation.navigate("AutomaticCheckoutCalculation")} />
<CustomTextItem content={'Polling'}  onPress={() => navigation.navigate("Polling")} />
<CustomTextItem content={'Repost'}  onPress={() => navigation.navigate("Repost")} />
<CustomTextItem content={'FeatureSettings'}  onPress={() => navigation.navigate("FeatureSettings")} />
<CustomTextItem content={'SelfdestructMessaging'}  onPress={() => navigation.navigate("SelfdestructMessaging")} />
<CustomTextItem content={'AuctionBidding'}  onPress={() => navigation.navigate("AuctionBidding")} />
<CustomTextItem content={'Pagination'}  onPress={() => navigation.navigate("Pagination")} />
<CustomTextItem content={'prioritise'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'amazoninteractivevideoservice'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'customisableuserprofiles'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'cache'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'photofilters'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'notes'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'productquickview'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'applelogin'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />

          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? '100vh' : 'auto',
    backgroundColor: "#F5FCFF"
  },
  container: {
    flex: 1
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white"
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: 16,

    padding: 10
  },
  button: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  header: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  item: {
    backgroundColor: '#00000000',
    padding: 18,
    color: '#6200EE',
    fontSize: 16,
    fontWeight: 'normal'
  }
});
// Customizable Area End
export default HomeScreen;