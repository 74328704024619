import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import AsyncStorage from "@react-native-async-storage/async-storage";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface Body {
  [key: string] : string | number[]
}

interface Attribute {
  [key: string]: string | number | string[]
}

interface PollListResponse {
  id: string;
  type: string;
  attributes: Attribute
}

interface ApiData {
  contentType: string; 
  method: string; 
  endPoint: string; 
  body?: Body; 
  type?: string;
}

interface S {
  // Customizable Area Start
  currentQuestion: string;
  selectedOption: string[];

  showError: boolean;
  isLoading: boolean;
  pollListResponse: PollListResponse[];
  question: string | number | string[];
  options: string[];
  currentQuestionIndex: number;
  selectedAnswers: string[][];
  isSubmitted: boolean;
  rightAnswerCount: number;
  isNext: boolean;
  isQuestionSubmitted:boolean;
  token:string | null;
  selectedAnswer: number[];
  showResults:boolean

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export interface Option {
  number: string;
  text: string;
}

export default class PollingController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getPollingListId: string;
  submitPollId: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    this.getPollingListId = "";
    this.submitPollId = "";

    this.state = {
      // Customizable Area Start
      currentQuestion: "que1",
      selectedOption: [],
      showError: false,
      isLoading: false,
      pollListResponse: [],
      currentQuestionIndex: 0,
      question: "",
      options: [],
      selectedAnswers: [],
      isSubmitted: false,
      rightAnswerCount: 0,
      isNext: true,
      isQuestionSubmitted:false,
      token:"",
      selectedAnswer:[],
      showResults:false

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      this.handleApiSuccessResponse(message);
      this.handleApiErrorResponse(message); 
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await this.getToken();
    await this.getPollList();
  }

  // TODO: getToken function was missing while lite certifying. It was copied from another block of the project to pass from pipeline. But note that, this is not the best way to obtain token.
  async getToken() {
    let token: any = await AsyncStorage.getItem('LOGIN_TOKEN')
    this.setState({ token })
  };

  apiCall = async (data: ApiData) => {
    const { contentType, method, endPoint, body, type } = data
    let token: string | null = this.state.token

    const header = {
      'Content-Type': contentType,
      token,
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    )
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    )
    body && type != 'formData' ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )

      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.setState({ isLoading: true});
    return requestMessage.messageId;
  }
  
  handleOptionSelection(option: string) {
    if(this.state.pollListResponse[
      this.state.currentQuestionIndex
    ].attributes.question_type === "radio_button") {
      this.setState({ selectedOption: [option], showError: false })
    } else if(this.state.pollListResponse[
      this.state.currentQuestionIndex
    ].attributes.question_type === "multiple_choice"){
      this.setState({ selectedOption: [...this.state.selectedOption, option], showError: false })
    }else {
      this.setState({ selectedOption: [], showError: false })
    }
  }

  handleNext = () => {
    if(this.state.selectedOption.length > 0){
      const selectedAnswers = [...this.state.selectedAnswers, this.state.selectedOption]
    this.setState({ isNext: false, selectedAnswers, showError: false });
    }else{
      this.setState({ showError: true});
    }
  }

  async handleSubmit() {
      if(this.state.currentQuestionIndex + 1 === this.state.pollListResponse.length) {
        const {rightIds, worngIds} = this.getFilterIdsAndWrongIds(this.state.selectedAnswers);
        this.setState({rightAnswerCount: rightIds.length});
        this.submitPollId = await this.apiCall({
          contentType: configJSON.contentType,
          method: configJSON.apiPostMethod,
          endPoint: configJSON.apiEndPointSubmitPoll,
          body: {
            ids: rightIds,
            worngIds
          }
        })
      }else {
        const currentQuestionIndex = this.state.currentQuestionIndex + 1;
        this.setState({ 
          isNext: true,
          currentQuestionIndex, 
          question: this.state.pollListResponse[currentQuestionIndex].attributes.question,
          options: this.getOptionList(this.state.pollListResponse[currentQuestionIndex].attributes),
          selectedOption: [], 
          showError: false
        });
      }
  }



  getPollList = async () => {
    this.getPollingListId = await this.apiCall({
      contentType: configJSON.contentType,
      method: configJSON.apiGetMethod,
      endPoint: configJSON.apiEndPointGetPollingList,
    })
  };

  getPollingListSuccessCallBack = (pollList: PollListResponse[]) => {
    this.setState({ 
      isLoading: false,
      pollListResponse: pollList,
      question: pollList[0].attributes.question,
      options: this.getOptionList(pollList[0].attributes),
    })
  };


  getPollingListFailureCallBack = (errorReponse: string) => {
    this.setState({ isLoading: false, })
    this.parseApiCatchErrorResponse(errorReponse);
  };

  submitPollSuccessCallBack = () => {
    this.setState({ isLoading: false, isSubmitted: true })
  };


  submitPollFailureCallBack = (errorReponse: string) => {
    this.setState({ isLoading: false });
    this.parseApiCatchErrorResponse(errorReponse);
  };

  goHome = () => {
    this.props.navigation.goBack()
  }



  getOptionList = (data: Attribute) => {
    const options: string[] = [];
    Object.keys(data).forEach((keyName: string) => {
      if(typeof keyName === "string" && keyName.includes("option") && data[keyName] !== "") {
        options.push(data[keyName].toString());
      }
      return keyName;
    })
    return options;
  }

  getFilterIdsAndWrongIds = (answers: string[][]) => {
    const rightIds: number[] = [];
    const worngIds: number[] = [];
    answers.forEach((answer, index) => {
      const is_correct = this.state.pollListResponse[index].attributes.is_correct;
      Array.isArray(is_correct) && JSON.stringify(is_correct) === JSON.stringify(answer) 
        ? rightIds.push(Number(this.state.pollListResponse[index].id))
        : worngIds.push(Number(this.state.pollListResponse[index].id))
    })
    return {rightIds, worngIds};
  }  
  
  getApiCommonResponseDetail = (message: Message) => {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    )
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    )
    const errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    )
    return {apiRequestCallId, responseJson, errorReponse}
  }

  handleApiSuccessResponse = (message: Message) => {
    const {apiRequestCallId, responseJson, errorReponse} = this.getApiCommonResponseDetail(message);
    if (responseJson && !responseJson.errors && !errorReponse) {
      if (apiRequestCallId === this.getPollingListId) {
        this.getPollingListSuccessCallBack(responseJson.data)
      }
      if (apiRequestCallId === this.submitPollId) {
        this.submitPollSuccessCallBack()
      }
    }
  }

  handleApiErrorResponse = (message: Message) => {
    const {apiRequestCallId, responseJson, errorReponse} = this.getApiCommonResponseDetail(message);
    if (responseJson?.errors || !errorReponse?.errors) {
      if (apiRequestCallId === this.getPollingListId) {
        this.submitPollFailureCallBack(responseJson?.errors || errorReponse?.errors)
      }
      if (apiRequestCallId === this.submitPollId) {
        this.getPollingListFailureCallBack(responseJson?.errors || errorReponse?.errors)
      }
    }
  }

  getBackgroundcolor = (option: string) => {
    let bgColor = {background: "#b9b9b9", color: "#fff"};
    const is_correct = this.state.pollListResponse[this.state.currentQuestionIndex].attributes.is_correct;
    if(this.state.isNext && this.state.selectedOption.includes(option)) {
      bgColor = {background: "#2196f3", color: "#FFF"}
    }
    if(!this.state.isNext && Array.isArray(is_correct)) {
      const wrongSelection = this.state.selectedOption.filter(selOp => !is_correct.includes(selOp))
      if(wrongSelection.includes(option)){
        bgColor = {background: "#f75e5e", color: "#FFF"}
      }
      if(is_correct.includes(option)){
        bgColor = {background: "#4fb711", color: "#FFF"}
      }
    }
    return bgColor
  }
  // Customizable Area End
}
