import React from "react";

// Customizable Area Start
import { Container, Input, Button, Typography } from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import SpellCheckController, {
  Props,
  configJSON,
} from "./SpellCheckController";

export default class SpellCheck extends SpellCheckController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  noSuggestionDiv = () => (
    <div data-test-id="noSuggestionComponent" className="no-suggestions">
      <span role="img" aria-label="tear emoji">
        😪
      </span>
      <em>{configJSON.noSuggestionText}</em>
    </div>
  );

  renderSuggestionsList = () => {
    const {
      showSuggestions,
      filteredSuggestions,
      activeSuggestionIndex,
      wordInput,
    } = this.state;

    if (!showSuggestions || !wordInput) {
      return null;
    } else if (filteredSuggestions.length === 0) {
      return this.noSuggestionDiv;
    }

    return (
      <ul className="suggestions" data-test-id="suggestionList">
        {filteredSuggestions.map((suggestion: string, index: number) => {
          const isActive = index === activeSuggestionIndex;
          const listItemDataTestId = "suggestionListItem_" + index;

          return (
            <li
              data-test-id={listItemDataTestId}
              style={
                isActive
                  ? webStyle.selectedSuggestionItem
                  : webStyle.suggestionItem
              }
              key={suggestion}
              onClick={() => this.onClickSuggestion(suggestion)}
            >
              {suggestion}
            </li>
          );
        })}
      </ul>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container maxWidth={"sm"}>
            <div style={webStyle.mainWrapper}>
              <Typography>{configJSON.labelTitleText}</Typography>
              <>
                <span style={{ marginRight: 10 }}>
                  {configJSON.labelBodyText}
                </span>

                <Input
                  data-test-id="wordInput"
                  type="text"
                  onChange={(event) => this.onChange(event.target.value)}
                  onKeyDown={(event) => this.onKeyDown(event.key)}
                  value={this.state.wordInput}
                />
                <Button
                  data-test-id="findButton"
                  style={webStyle.buttonStyle}
                  onClick={this.findMatches}
                >
                  {configJSON.findNearWordsText}
                </Button>
                {this.renderSuggestionsList()}
              </>
            </div>
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  suggestionItem: {
    cursor: "pointer",
  },
  selectedSuggestionItem: {
    cursor: "pointer",
    backgroundColor: "red",
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column" as 'column',
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
