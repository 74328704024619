import React from "react";

// Customizable Area Start
// Customizable Area End

import FacialTrackingController, {
  Props,
  configJSON,
} from "./FacialTrackingController.web";

// Customizable Area Start
// Customizable Area End

export default class FacialTracking extends FacialTrackingController {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    const { expressionInfo, age, gender, canDetect } = this.state;

    const expression =
      expressionInfo && this.state.expressionMap[expressionInfo.expression];
    // Customizable Area End

    return (
      // Customizable Area Start
      <div style={webStyle.main}>
        <h1>{configJSON.faceRecognitionWebcam}</h1>
        <button
          type="button"
          onClick={this.toggleCanDetect}
          style={webStyle.button}
          data-test-id="detectFace"
        >
          {!canDetect ? configJSON.detectFaces : configJSON.detectingFaces}
        </button>
        {canDetect ? (
          <div>
            {
              <p data-testid="expression">
                {configJSON.expression}:{" "}
                {expression
                  ? `${expressionInfo?.expression} ${expression}`
                  : configJSON.loading}
              </p>
            }
            {
              <p>
                {configJSON.age}: {age ? age : configJSON.loading}
              </p>
            }
            {
              <p>
                {configJSON.gender}: {gender ? gender : configJSON.loading}
              </p>
            }
          </div>
        ) : (
          ""
        )}

        <div style={webStyle.center}>
          <div style={webStyle.videoContainer}>
            <video
              data-testid={"video"}
              ref={this.videoRef}
              autoPlay
              onPlay={this.onPlay}
              style={webStyle.position}
            ></video>
            {canDetect ? (
              <canvas ref={this.overlayRef} style={webStyle.position} />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  main: {
    height: "100%",
    maxWidth: "100vw",
  },
  button: {
    cursor: "pointer",
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    overFlowX: "hidden",
    height: "100%",
    alignSelf: "stretch",
    overflowX: "hidden",
  },
  videoContainer: { position: "relative" },
  position: {
    position: "absolute",
    left: 0,
    top: 0,
    transform: "translateX(-50%)",
    overFlowX: "hidden",
  },
} as const;
// Customizable Area End
