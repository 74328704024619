import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import terms from "../assets/english-words-small.json";
const SymSpell = require("mnemonist/symspell");
// Customizable Area End

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  filteredSuggestions: string[];
  activeSuggestionIndex: number;
  showSuggestions: boolean;
  wordInput: string;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class SpellCheckController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  indexedTerms: {
    search: (searcString: string) => Array<{ term: string; distance: number }>;
  };
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.subScribedMessages = [
      // Customizable Area Start
      // Customizable Area End  
    ];

    this.state = {
      // Customizable Area Start
      filteredSuggestions: [],
      activeSuggestionIndex: -1,
      showSuggestions: false,
      wordInput: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    this.indexedTerms = { search: (searchString) => [] };
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    this.createIndexStructureFromTerms();
  }

  createIndexStructureFromTerms = () => {
    const options = {
      maxDistance: 2,
    };

    this.indexedTerms = SymSpell.from(terms, options);
  };

  onClickSuggestion = (suggestion: string) => {
    this.setState({
      showSuggestions: false,
      activeSuggestionIndex: 0,
      wordInput: suggestion,
      filteredSuggestions: [],
    });
  };


  findMatches = () => {
    const { wordInput, filteredSuggestions } = this.state;
    if (wordInput.length < 2) {
      if (filteredSuggestions.length > 0) {
        this.setState({
          filteredSuggestions: [],
          activeSuggestionIndex: -1,
          showSuggestions: false,
        });
      }
    } else {
      const isTheWordUpperCase = wordInput === wordInput.toLocaleUpperCase();
      const isTheWordCamelCase =
        wordInput ===
        wordInput.charAt(0).toLocaleUpperCase() +
        wordInput.slice(1).toLocaleLowerCase();

      let lowercaseWordInput = wordInput.toLocaleLowerCase();
      let suggestions = this.indexedTerms.search(lowercaseWordInput);

      let filteredSuggestions = suggestions
        .filter((sgstn) => sgstn.term !== lowercaseWordInput)
        .sort((sgstn1, sgstn2) => sgstn1.distance - sgstn2.distance)
        .slice(0, 10)
        .map((sgstn) => sgstn.term);

      if (isTheWordUpperCase)
        filteredSuggestions = filteredSuggestions.map((sgstn: string) =>
          sgstn.toLocaleUpperCase()
        );
      else if (isTheWordCamelCase)
        filteredSuggestions = filteredSuggestions.map(
          (sgstn: string) => sgstn.charAt(0).toLocaleUpperCase() + sgstn.slice(1)
        );

      this.setState({
        filteredSuggestions,
        activeSuggestionIndex: filteredSuggestions.length === 0 ? -1 : 0,
        showSuggestions: true,
      });
    }
  };

  setActiveSuggestionIndex = (activeSuggestionIndex: number) => {
    this.setState({ activeSuggestionIndex });
  };

  onChange = (wordInput: string) => {
    this.setState({ wordInput }, this.findMatches);
  };

  selectSuggestion = (suggestion: string) => {
    this.setState({
      activeSuggestionIndex: -1,
      showSuggestions: false,
      wordInput: suggestion,
    });
  };

  onKeyDown = (pressedKey: string) => {
    const { filteredSuggestions, activeSuggestionIndex } = this.state;
    if (
      pressedKey === "Enter" &&
      activeSuggestionIndex > -1 &&
      filteredSuggestions.length > 0
    ) {
      this.selectSuggestion(filteredSuggestions[activeSuggestionIndex]);
    } else if (pressedKey === "ArrowUp") {
      if (activeSuggestionIndex === 0)
        this.setActiveSuggestionIndex(filteredSuggestions.length - 1);
      else this.setActiveSuggestionIndex(activeSuggestionIndex - 1);
    } else if (pressedKey === "ArrowDown") {
      if (activeSuggestionIndex === filteredSuggestions.length - 1)
        this.setActiveSuggestionIndex(0);
      else this.setActiveSuggestionIndex(activeSuggestionIndex + 1);
    }
  };
  // Customizable Area End
}
