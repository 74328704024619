import React from "react";

import {
  Container,
  Box,
  Button, // Customizable Area Start
  Modal,
  Typography,
  Card, // Customizable Area End
  CardContent,
} from "@mui/material";

// Customizable Area Start
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

import RepostController, { Props } from "./RepostController";
import "@mui/styles";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

export default class Repost extends RepostController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container maxWidth={"lg"}>
            <Box sx={webStyle.mainWrapper} data-testid="list">
              <Modal
                open={this.state.isVisibleModal}
                onClose={this.handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                data-testid="ReasonModal"
              >
                <Box sx={webStyle.modelStyle}>
                  <h1 style={{ fontSize: "18px" }}>
                    {this.state.selectedContent.Postid}
                  </h1>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <label style={{ textAlign: "left", fontSize: "14px" }}>
                      Add Message
                    </label>
                    <textarea
                      data-testid="message"
                      value={this.state.messageInput}
                      onChange={(event) => this.onChange(event.target.value)}
                      placeholder="Add Message"
                      rows={10}
                      cols={50}
                      style={{
                        border: "1px solid black",
                        borderRadius: "5px",
                        fontSize: "16px",
                        marginTop: "10px",
                      }}
                    />
                    <Button
                      style={{ textTransform: "none", color: "blue" }}
                      data-testid="SendButton"
                      onClick={this.HandleConfirm}
                    >
                      Send
                    </Button>
                  </Box>
                </Box>
              </Modal>
              {(this.state.allPosts || []).map((item) => (
                <Card
                  style={{
                    maxWidth: 200,
                    minWidth: 200,
                    padding: "10px",
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                  key={item.id}
                  data-testid={item.name}
                >
                  <Typography
                    style={{
                      fontSize: "16px",
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    This is Post
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <Typography
                      style={{
                        fontSize: "16px",
                        fontWeight: 400,
                        textAlign: "left",
                      }}
                    >
                      {item.id}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "16px",
                        fontWeight: 400,
                        textAlign: "center",
                        marginLeft: "10px",
                      }}
                    >
                      {item.user}
                    </Typography>
                  </Box>
                  <p
                    style={{
                      fontSize: "12px",
                      fontWeight: 100,
                      textAlign: "left",
                    }}
                  >
                    {this.getDateFormate(item.created_at)}
                  </p>
                  <CardContent style={{ maxHeight: "50px", padding: "5px" }}>
                    <Typography style={{ fontSize: "10px" }}>
                      {item.additional_note}
                    </Typography>
                  </CardContent>
                  <Button
                    data-testid={`button_${item.name}`}
                    onClick={() => this.showRepost(item.id)}
                    style={{ textTransform: "none" }}
                  >
                    Repost
                  </Button>
                </Card>
              ))}
            </Box>
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    alignItems: "center",
    padding: "30px",
    background: "#fff",
    maxWidth: "75%",
    gap: "30px",
    flexWrap: "wrap",
  },
  modelStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "10px",
    textAlign: "center",
    px: 5,
    py: 1,
  },
  postStyle: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid black",
    padding: "5px",
  },
};
// Customizable Area End
