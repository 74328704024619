Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "SpellCheck";
exports.labelBodyText = "SpellCheck Body";

exports.btnExampleTitle = "CLICK ME";
exports.noSuggestionText = "Sorry, no suggestions.";
exports.findNearWordsText = "Find Near Words";
exports.suggestionsHeaderText = "Top 10 Words";
// Customizable Area End