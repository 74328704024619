Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "VideoTrimmer";
exports.labelBodyText = "VideoTrimmer Body";
exports.uploadBtn = 'Upload Video';
exports.convertVideoBtn = 'Open Trimmer';
exports.cancelBtn = 'Cancel';
exports.trimBtn = 'Trim';
exports.activityIndicatorUploadingTxt = 'Uploading Video';
exports.activityIndicatorTrimmingTxt = 'Trimming Video';
exports.dragAndDropText = "Drag 'n' drop some files here, or click to select files";
exports.permissionRequestTitle = 'Permissions for write access';
exports.permissionRequestMessage = 'Give permission to your storage to write trimmed video file';
exports.permissionDeniedAlertMessage = "You had to give storage permission to be able to download trimmed videos."
exports.ok = 'OK';
exports.numberOfSnapShot = 10;
exports.trimButtonText ="TRIM";
exports.trimButtonTextWhileTrim = "Please Wait...";
exports.canelButtonText = "CANCEL";
exports.errorOnLoadingFfmpeg = "Error occured while loading ffmpeg!";
exports.fileSelectionError = "Error occured while processing the selected video!";
exports.fileFFMPEGCopyError = "Error occured while processing the selected video!";
exports.trimSuccessOnAndroid = "Trimmed video stored to your downloads folder.";
exports.trimSuccessOnIOS = "Trimmed video stored to the folder of this application.";
exports.trimSuccessOnWeb = "Video is trimmed and will be downloaded";
exports.trimErrorText = "Error occured while trimming!";
exports.btnExampleTitle = "CLICK ME";
exports.rejectedFileSelectionError = "You selected an unsupported file. Please select a vido file.";
// Customizable Area End