import React from "react";

// Customizable Area Start
// Customizable Area End

import CustomerDialpadKeyboardController, {
  Props,
} from "./CustomerDialpadKeyboardController";


export default class CustomerDialpadKeyboard extends CustomerDialpadKeyboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  contactlist=( name:string,phone:string )=>{ 
    return(
      <div
      style={{
  
          border: '10px solid rgba(0, 0, 0, 0.05)',
        width: '100%',
        marginTop: 10,
        paddingBottom: 15,
        borderWidth: 0.4,padding:10,
      }}
    >
    
      <div >
        <text
          style={{
            fontSize: 15,
            marginTop: 1,
          }}
        >
          {name}
        </text>
      </div>
      <div
      >
        <text
          style={{
            fontSize: 15,
            fontFamily: "DMSans-Bold",
            color: "#64BA94",
          }}
        >
          {phone}
        </text>
      </div>
    </div>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start

        <div style={{height:"100%",flexDirection:'row'}}>

          <div  style={{height:"50%",flexDirection:'row', 
  left: 0,
  bottom: 0,
  right: 0,
  overflow: "auto",
 }}  >
          
          {this.state.contactlist.map((item) => (
               this.contactlist(item.attributes.first_name,item.attributes.phone_number)
              ))}
           </div>

           <div  style={{height:"50%",flexDirection:'row'}} >
      <div>
          <div
      style={{
        flex:1,
        marginTop: 10,
        paddingBottom: 15,
        borderWidth: 5,padding:5,
      }}
    >
      <button 
       data-test-id="navigate_contact"
       onClick={() => this.pageredirect()}
      >
      <img
        style={{ height: 25, width: 25 }}
        src={require("../assets/addCreditIcon.png")}
      />

  </button>

  <text
          style={{
            fontSize: 17,
            marginLeft:20,
            color: "#64BA94",
          }}
        >
          {this.state.text}
        </text>
         </div>



            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}  >
              <div style={webStyle.button}>
                <button
                  data-test-id="number1"
                  onClick={() => this.onPress("1", "")}>
                  <div style={
                    webStyle.View
                  }>
                    1
                  </div>

                </button>
              </div>
              <div style={webStyle.button}>
                <button
                  data-test-id="number2"
                  onClick={() => this.onPress("2", 'ABC')}>
                  <div style={
                    webStyle.View
                  }>
                    2<br />{"ABC"}
                  </div>
                </button>
              </div>
              <div style={webStyle.button}>
                <button
                  data-test-id="number3"
                  onClick={() => this.onPress("3", 'DEF')}>
                  <div style={
                    webStyle.View
                  }>
                    3<br />{"DEF"}

                  </div>
                </button>
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}  >
              <div style={webStyle.button}>
                <button
                  data-test-id="number4"
                  onClick={() =>  this.onPress("4", 'GHI')}>
                  <div style={
                    webStyle.View
                  }>

                    4<br />{"GHI"}
                  </div>

                </button>
              </div>
              <div style={webStyle.button}>
                <button
                  data-test-id="number5"
                  onClick={() =>  this.onPress("5", 'JKL')}>
                  <div style={
                    webStyle.View
                  }>

                    5<br />{"JKL"}


                  </div>
                </button>
              </div>
              <div style={webStyle.button}>
                <button
                  data-test-id="number6"
                  onClick={() =>this.onPress("6", 'MNO')}>
                  <div style={
                    webStyle.View
                  }>
                    6<br />{"MNO"}


                  </div>
                </button>
              </div>
            </div>

            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}  >
              <div style={webStyle.button}>
                <button
                  data-test-id="number7"
                  onClick={() => this.onPress("7", 'PQRS')}>
                  <div style={
                    webStyle.View
                  }>

                    7<br />{"PQRS"}

                  </div>

                </button>
              </div>
              <div style={webStyle.button}>
                <button
                  data-test-id="number8"
                  onClick={() => this.onPress("8", 'TUV')}>
                  <div style={
                    webStyle.View
                  }>

                    8<br />{"TUV"}


                  </div>
                </button>
              </div>
              <div style={webStyle.button}>
                <button
                  data-test-id="number9"
                  onClick={() => this.onPress("9", 'WXYZ')}>
                  <div style={
                    webStyle.View
                  }>
                    9<br />{"WXYZ"}


                  </div>
                </button>
              </div>
            </div>


            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}  >
              <div style={webStyle.button}>
                <button
                  data-test-id="onback"
                  onClick={() => this.onPressBackSpace()}>
                  <div style={
                    webStyle.View
                  }>

                    Back

                  </div>

                </button>
              </div>
              <div style={webStyle.button}>
                <button
                  data-test-id="number0"
                  onClick={() => this.onPress("0", '')}>
                  <div style={
                    webStyle.View
                  }>

                    0<br />{"+ "}


                  </div>
                </button>
              </div>
              <div style={webStyle.button}>
                <button>
                  <div style={
                    webStyle.View
                  }>
                    #


                  </div>
                </button>
              </div>
              </div>
            </div>
          </div>

</div>

      

        // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  View:
  {
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    padding: 5,
    width: "33.333333333%",
  },
}
// Customizable Area End
