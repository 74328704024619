import React from 'react';

// Customizable Area Start
import {
  Container,
  Box,
  TableHead,
  TableBody,
  Table,
  TableRow,
  TableCell,
  Typography,
} from "@mui/material";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from '@mui/material/styles';

import { IStudentDetail } from './types/types';

const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
      contrastText: '#fff',
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: '20px 0px',
    },
  },
});
// Customizable Area End

import PtStudentInfoScreenController, {
  Props,
  configJSON,
} from './PtStudentInfoScreenController';

export default class PtStudentInfoScreen extends PtStudentInfoScreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderStudentInfo = () => {
    const { studentDetails } = this.state;

    return (
      <>
        <Typography>
          {configJSON.studentIdText}
          {this.state.accountId}
          {/* {configJSON.courseNameText} */}
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{configJSON.courseIdText}</TableCell>
              <TableCell>{configJSON.testNameText}</TableCell>
              <TableCell>{configJSON.creditsText}</TableCell>
              <TableCell>{configJSON.passPercentageText}</TableCell>
              <TableCell>{configJSON.scorePercentageText}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {studentDetails?.map((detail: IStudentDetail, index: number) => (
              <TableRow key={`testScore-${index}`}>
                <TableCell>
                  {detail.attributes.performance_tracker_course_id}
                </TableCell>
                <TableCell>{detail.attributes.name}</TableCell>
                <TableCell>{detail.attributes.credits}</TableCell>
                <TableCell>{detail.attributes.pass_percentage}</TableCell>
                <TableCell>{detail.attributes.score_percentage}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container maxWidth={'md'}>
            <Box sx={webStyle.mainWrapper}>
              <Typography variant="h6">{configJSON.labelTitleText}</Typography>
              <Box>{this.renderStudentInfo()}</Box>
            </Box>
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }
  // Customizable Area End
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: 'flex',
    fontFamily: 'Roboto-Medium',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '30px',
    background: '#fff',
  },
  inputStyle: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.6)',
    width: '100%',
    height: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  buttonStyle: {
    width: '100%',
    height: '45px',
    marginTop: '40px',
    border: 'none',
    backgroundColor: 'rgb(98, 0, 238)',
  },
};
// Customizable Area End
