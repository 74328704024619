// Customizable Area Start
// @ts-nocheck
import React from "react";

import { AutomaticField } from "../types";

// Customizable Area End

// Customizable Area Start
const DataContext = React.createContext<AutomaticField>();
// Customizable Area End

// Customizable Area Start

export default DataContext;

// Customizable Area End
