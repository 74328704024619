Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.httpPostMethod = "POST";
exports.pleaseEnterAValidEmail = "Please enter a valid email";
exports.emailIsRequired = "Email is required";
exports.invalidEmailAddress = "Invalid email address";
exports.emailPlaceholder = "Email";
exports.btnColor = "#6200EE";
exports.provideCredentialsApiEndpoint = "account_transfer/account_transfer";
exports.accountTransferApiEndpoint = "/account_transfer/email_account_transfer";
exports.accountTransferRequestMethod = "POST";
exports.enterProvideCredentialsText =
  "Enter email account to send your credentials:";
exports.enterEmailText = "Enter new email to transfer your account to:";
exports.emailInputPlaceHolder = "Email";
exports.submitButtonTitle = "Submit";
exports.closeButtonTitle = "Close";
exports.modalSuccessMessage =
  "Successfully Transferred Account! Check your email";
exports.modalFailMessage = "Could not transfer account";
exports.modalSuccessButton = "Ok";
exports.modalFailButton = "Try Again";
exports.error = "Error";
exports.success = "Success";
exports.successMessage = "Operation is successful";
exports.provideCredentials = "Provide Account Credentials";
exports.accountTransfer = "Transfer Account";

// Customizable Area End
