import React from "react";

import {
  // Customizable Area Start
  Container,
  Box,
  Button, // Customizable Area End
  Typography,
  adaptV4Theme,
} from "@mui/material";
import "@mui/styles";

// Customizable Area Start
import {
  CheckboxField,
  DatePickerField,
  TextInputField,
  TimePickerField,
} from "./components/AutomaticFields.web";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";

import AutomaticFormCreationController, {
  Props,
  configJSON,
} from "./AutomaticFormCreationController.web";

import { FieldName } from "./types";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
// Customizable Area End

export default class AutomaticFormCreation extends AutomaticFormCreationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderInputType = (type: string, name: string) => {
    switch (type) {
      case "text":
      case "string":
        return (
          <TextInputField
            field_type={"text"}
            field_name={name}
            handleInputChange={this.onNewChangeValues}
          />
        );
      case "integer":
      case "float":
        return (
          <TextInputField
            field_type={type}
            field_name={name}
            handleInputChange={this.onNewChangeValues}
          />
        );
      case "date":
        return (
          <DatePickerField
            field_name={name}
            handleInputChange={this.onNewChangeValues}
          />
        );
      case "time":
        return (
          <TimePickerField
            field_name={name}
            handleInputChange={this.onNewChangeValues}
          />
        );
      case "boolean":
        return (
          <CheckboxField
            field_name={name}
            handleInputChange={this.onNewChangeValues}
          />
        );
    }
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container maxWidth="sm">
            <Box sx={webStyle.mainWrapper}>
              <Typography variant="h6">
                {" "}
                {this.state.automaticData?.attributes?.name ?? ""}
              </Typography>
              <Typography variant="subtitle1" component="div">
                {this.state.automaticData?.attributes?.description ?? ""}
              </Typography>
              {this.state.automaticData?.attributes?.field_name.map(
                (item: FieldName, index: number) => {
                  return (
                    <Box
                      key={index}
                      sx={
                        (item.field_type ?? "text") != "boolean"
                          ? webStyle.dynamicInputStyle
                          : webStyle.checkboxInputStyle
                      }
                    >
                      {(item.field_type ?? "text") != "boolean" && (
                        <Typography variant="subtitle1" component="div">
                          {item.field_name ?? ""}
                        </Typography>
                      )}
                      {this.renderInputType(
                        item.field_type ?? "text",
                        item.field_name ?? ""
                      )}
                    </Box>
                  );
                }
              )}
              <Box
                data-test-id="btnAddExample"
                onClick={() => this.doButtonPressed()}
                component="button"
                sx={webStyle.buttonStyle}
              >
                {configJSON.btnExampleTitle}
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  dynamicInputStyle: {
    width: "100%",
    height: "80px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  checkboxInputStyle: {
    width: "100%",
    height: "40px",
    marginLeft: "-10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
};
// Customizable Area End
