import React, { CSSProperties } from "react";
// Customizable Area Start
import { Box, Button, CircularProgress, Slider } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import Dropzone from "react-dropzone";
// Customizable Area End

import VideoTrimmerController, {
  Props,
  configJSON,
} from "./VideoTrimmerController.web";

// Customizable Area Start
interface IThumbComponentProps {
  "aria-valuenow"?: number;
}
// Customizable Area End

export default class VideoTrimmer extends VideoTrimmerController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderDropZone = () => {
    return (
      <Dropzone
        data-test-id="dropZone"
        onDrop={this.selectFile}
        maxFiles={1}
        accept={{ "video/*": [".mp4", ".mkv"] }}
      >
        {({ getRootProps, getInputProps }) => (
          <Box
            sx={webStyle.container}
            style={webStyle.dragAndDropStyle}
            {...getRootProps({ className: "dropzone" })}
          >
            <input {...getInputProps()} />
            <p>{configJSON.dragAndDropText}</p>
          </Box>
        )}
      </Dropzone>
    );
  };

  renderCustomThumbComponent = (props: IThumbComponentProps) => {
    const selectedtime = this.convertDurationToString(
      ((props && props["aria-valuenow"] ? props["aria-valuenow"] : 0) *
        this.videoDuration) /
        100
    ).slice(0, 8);

    return (
      <span {...props}>
        <span className="bar" />
        <span className="bar" />
        <span className="bar" />
        <span style={webStyle.sliderThumbComponentStyle as CSSProperties}>
          {selectedtime}
        </span>
      </span>
    );
  };

  renderSelectedVideo = () => {
    const { selectedFileUrl } = this.state;

    return (
      <Box sx={webStyle.editorContainer}>
        <video
          ref={this.videoRef}
          controls
          style={webStyle.video}
          onLoadedMetadata={this.handleLoadedData}
          data-test-id="selected-video"
        >
          <source src={selectedFileUrl} type={"video/mp4"} />
        </video>
      </Box>
    );
  };

  renderButtons = () => {
    const { isTrimStarted, selectedFileUrl } = this.state;
    return (
      <Box sx={webStyle.btnContainer}>
        <Button
          data-test-id="trimButton"
          onClick={this.trimVideo}
          disabled={isTrimStarted}
          style={webStyle.buttonStyle}
        >
          {isTrimStarted && <CircularProgress variant="indeterminate" />}
          {isTrimStarted
            ? configJSON.trimButtonTextWhileTrim
            : configJSON.trimButtonText}
        </Button>
        {!isTrimStarted && !!selectedFileUrl && (
          <Button
            style={webStyle.buttonStyle}
            onClick={this.handleCancel}
            data-test-id="cancelButton"
          >
            {configJSON.canelButtonText}
          </Button>
        )}
      </Box>
    );
  };

  renderCircularProgress = () => {
    return (
      <div style={webStyle.circularProgressContainer as CSSProperties}>
        <CircularProgress color="secondary" />
      </div>
    );
  };

  renderSlider = () => {
    const { ssArray } = this.state;
    const CustomSlider = withStyles(webStyle.slider)(Slider) as typeof Slider;

    return (
      <div style={webStyle.sliderOuterContainer as CSSProperties}>
        <div style={webStyle.sliderInnerContainer as CSSProperties}>
          <CustomSlider
            data-test-id="slider"
            components={{
              Thumb: this.renderCustomThumbComponent,
            }}
            onChange={this.handleSliderChange}
            defaultValue={[0, 100]}
          />
        </div>

        {ssArray.map((ssImage) => {
          const objectUrl = URL.createObjectURL(ssImage);
          return (
            <img
              key={objectUrl}
              style={webStyle.ssImageStyle}
              src={objectUrl}
            />
          );
        })}
      </div>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { isFfmpegLoaded, selectedFileUrl, ssArray } = this.state;
    const isVideoAndSnapShotsLoaded =
      ssArray.length === configJSON.numberOfSnapShot;

    return (
      <div style={webStyle.mainWrapper as CSSProperties}>
        {!isFfmpegLoaded && this.renderCircularProgress()}
        {!selectedFileUrl && this.renderDropZone()}
        {!!selectedFileUrl && this.renderSelectedVideo()}
        {!!selectedFileUrl && this.renderSlider()}
        {isVideoAndSnapShotsLoaded && this.renderButtons()}
      </div>
    );
    // Customizable Area End
  }
}

const webStyle = {
  // Customizable Area Start
  mainWrapper: {
    fontFamily: "Roboto-Medium",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 30,
    position: "relative",
  },
  dragAndDropStyle: {
    width: 400,
    height: 400,
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
    color: "#fff",
    marginLeft: "10px",
    marginRight: "10px",
    padding: "15px",
  },
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    height: "40vh",
    borderWidth: 2,
    justifyContent: "center",
    borderRadius: 10,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
    cursor: "pointer",
  },
  btnContainer: {
    display: "flex",
    justifyContent: "center",
    my: 2,
  },
  editorContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  video: {
    width: "100%",
    cursor: "pointer",
    borderRadius: "1em",
    maxHeight: "50vh",
  },
  sliderOuterContainer: {
    display: "flex",
    width: 700,
    position: "relative",
    marginBottom: 30,
    marginTop: 20,
  },
  sliderInnerContainer: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 2,
    top: 0,
    left: 0,
  },
  slider: {
    root: {
      color: "#ffffff00",
      height: "100%",
      padding: 0,
    },
    thumb: {
      height: "100%",
      marginTop: 0,
      width: 9,
      borderRadius: 0,
      backgroundColor: "#e58282",
      boxShadow: "#ebebeb 0 2px 2px",
      "&:focus, &:hover, &$active": {
        boxShadow: "#ccc 0 2px 3px 1px",
      },
      "& .bar": {
        height: 9,
        width: 1,
        backgroundColor: "currentColor",
        marginLeft: 1,
        marginRight: 1,
      },
    },
    active: {},
    track: {
      height: "100%",
    },
    rail: {
      color: "#ffffff00",
      height: "100%",
    },
  },
  sliderThumbComponentStyle: {
    border: "2px solid #df0000",
    position: "absolute",
    bottom: -25,
    color: "black",
    fontSize: 14,
    padding: 3,
  },
  ssImageStyle: { width: "10%" },
  circularProgressContainer: {
    width: "100%",
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 2,
    backgroundColor: "#9795947a",
  },
  // Customizable Area End
};
