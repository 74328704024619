import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

export const config = require("./config");

// Customizable Area Start
import { isEmail, logoutUser } from "../../../framework/src/Utilities";
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountTransferSuccess: boolean;
  provideCredentialsModalOpen: boolean;
  accountTransferModalOpen: boolean;
  token: string;
  loading: boolean;
  modalErrorMessage: string;
  validationError: string;
  email: string;
  isAccountTransfer: boolean;
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class AccountTransferController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  provideCredentialsCallId: string;
  accountTransferCallId: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.provideCredentialsCallId = "";
    this.accountTransferCallId = "";
    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.handleTransferSuccess = this.handleTransferSuccess.bind(this);
    this.handleTransferFail = this.handleTransferFail.bind(this);
    this.goToLogin = this.goToLogin.bind(this);
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      accountTransferSuccess: false,
      provideCredentialsModalOpen: false,
      accountTransferModalOpen: false,
      token: "",
      loading: false,
      modalErrorMessage: "",
      validationError: "",
      email: "",
      isAccountTransfer: false,
      // Customizable Area End
    };
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getToken = () => {
    const message: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };
  // Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token });
    } else if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if (
        this.accountTransferCallId === requestCallId ||
        this.provideCredentialsCallId === requestCallId
      ) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        const errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        if (responseJson && !responseJson.errors) {
          this.handleTransferSuccess();
        } else if (responseJson && responseJson.errors) {
          let message = "";
          if (
            Array.isArray(responseJson.errors) &&
            responseJson.errors.length > 0
          ) {
            message = responseJson.errors[0].message;
          } else {
            message = responseJson.errors.message;
          }

          this.handleTransferFail(message);
        } else if (errorReponse) {
          this.handleTransferFail(errorReponse);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleTransferSuccess(): void {
    this.setState(
      { accountTransferSuccess: true, loading: false, email: "" },
      () => this.showAlert(config.success, config.successMessage)
    );
  }

  handleTransferFail(error: string): void {
    this.setState(
      {
        accountTransferSuccess: false,
        loading: false,
        email: "",
      },
      () => this.showAlert(config.error, error)
    );
  }

  sendProvideCredentialsRequest = () => {
    if (this.state.validationError) {
      return;
    }
    this.setState({ loading: true, accountTransferModalOpen: false });

    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    const body = {
      email: this.state.email,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.provideCredentialsCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      config.provideCredentialsApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      config.accountTransferRequestMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  sendAccountTransferRequest = () => {
    if (this.state.validationError) {
      return;
    }
    this.setState({ loading: true, accountTransferModalOpen: false });

    const header = {
      "Content-Type": "application/json",
      token: this.state.token,
    };

    const body = {
      email: this.state.email,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.accountTransferCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      config.accountTransferApiEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      config.accountTransferRequestMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleProvideCredentialsModalOpen = () => {
    this.setState({
      accountTransferModalOpen: true,
      isAccountTransfer: false,
    });
  };

  handleAccountTransferModalOpen = () => {
    this.setState({
      accountTransferModalOpen: true,
      isAccountTransfer: true,
    });
  };

  handleAccountTransferModalClose = () => {
    this.setState({
      isAccountTransfer: false,
      accountTransferModalOpen: false,
    });
  };

  handleLogout = () => {
    if (this.state.accountTransferSuccess) {
      this.logout();
      this.goToLogin();
    }
  };

  handleEmailChange = (value: string) => {
    this.setState({ email: value });
  };

  handleProvideCredentialsSubmit = () => {
    this.validateEmail(this.sendProvideCredentialsRequest);
  };

  handleAccountTransferSubmit = () => {
    this.validateEmail(this.sendAccountTransferRequest);
  };

  goToLogin() {
    const message: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  logout() {
    if (this.isPlatformWeb()) {
      localStorage.removeItem("authToken");
      localStorage.removeItem("saveUserInfo");
    } else {
      logoutUser();
    }
  }

  validateEmail = (callback?: () => void) => {
    const { email } = this.state;

    const { status } = isEmail("email", email);

    if (status) {
      this.setState({ validationError: "" }, callback);
    } else {
      if (email === "") {
        this.setState({ validationError: config.emailIsRequired });
      } else {
        this.setState({ validationError: config.invalidEmailAddress });
      }
    }
  };
  // Customizable Area End
}
