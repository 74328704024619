Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.getEventAPIMethod = "GET";
exports.registerAPIMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "EventRegistration";
exports.labelBodyText = "Event Registration";
exports.tokenKey = '';

exports.desciptionLabel = "Description : ";
exports.locationLabel = "Location : ";
exports.eventNameLabel = "Event Name : ";
exports.feeLabel = "Fee :"
exports.maxParticipants = "Max Participants : ";
exports.minParticipants = "Min Participants : ";

exports.registerEventButton = "Register Event";

exports.BASE_URL = "";
exports.getEventsEndPoint = "bx_block_eventregistration/event_blocks";
exports.registerEventEndPoint = "bx_block_eventregistration/account_event_blocks";
// Customizable Area End