Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.BASE_URL = '';
exports.tokenKey = 'token';

exports.deleteAccountEndPoint = 'bx_block_feature_settings/delete_accounts';
exports.logoutEndPoint = 'bx_block_feature_settings/logouts';
exports.changePasswordEndPoint = 'bx_block_feature_settings/change_passwords';
// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.labelTitleText = "FeatureSettings";
exports.labelBodyText = "FeatureSettings";
exports.exampleAPiMethodDele = "DELETE";

exports.logoutButtonText = "Logout";

exports.deleteAccountText = "Delete Account";
exports.deleteAccountButtonText = "Delete";

exports.newPasswordInputPlaceholder = "Enter New Password";
exports.changePasswordBtnText = "Change Password"
exports.notificationPreferenceBtnText = "Notification Preference"
exports.yesBtnText = "Yes"
exports.noBtnText = "No"


exports.token = "token"
exports.deleteAccountEndPoint = "bx_block_feature_settings/delete_accounts"
exports.logoutEndPoint = 'bx_block_feature_settings/logouts';
exports.changePasswordEndPoint = 'bx_block_feature_settings/change_passwords';
exports.notificationPreferenceEndPoint = "account_block/accounts"
// Customizable Area End