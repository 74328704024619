import React from 'react';

// Customizable Area Start
import {
  Container,
  Box,
  Input,
  Button,
  Typography,
  TextField,
  Select,
  MenuItem,
} from '@mui/material';

import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: '#fff',
      contrastText: '#fff',
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: '20px 0px',
    },
  },
});
// Customizable Area End

import ContactListController, {
  Props,
  configJSON,
} from './ContactListController.web';

export default class ContactList extends ContactListController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Container maxWidth={'sm'}>
            <Box sx={webStyle.mainWrapper}>
              <Typography variant="h6">{configJSON.labelTitleText}</Typography>
              <Typography variant="subtitle1" component="div">
                {configJSON.labelBodyText}
              </Typography>
              <Box sx={webStyle.textFieldStyles}>
                <TextField
                  style={webStyle.inputFieldFirstName}
                  onChange={this.handleChange}
                  id="outlined-basic"
                  label={configJSON.firstName}
                  variant="outlined"
                  name={'firstName'}
                  color="secondary"
                  value={this.state.firstName}
                  data-test-id={'text-firstname'}
                />
                <TextField
                  style={webStyle.inputFieldMiddleName}
                  onChange={this.handleChange}
                  id="outlined-basic"
                  label={configJSON.middleName}
                  variant="outlined"
                  name="middleName"
                  color="secondary"
                  value={this.state.middleName}
                  data-test-id={'text-middlename'}
                />
                <TextField
                  style={webStyle.inputFieldLastName}
                  onChange={this.handleChange}
                  id="outlined-basic"
                  label={configJSON.lastName}
                  variant="outlined"
                  name="lastName"
                  color="secondary"
                  value={this.state.lastName}
                  data-test-id={'text-lastname'}
                />
              </Box>
              <Box sx={webStyle.inputStyle}>
                <TextField
                  id="outlined-basic"
                  onChange={this.handleChange}
                  label={configJSON.organization}
                  variant="outlined"
                  name="organization"
                  color="secondary"
                  value={this.state.organization}
                  data-test-id={'text-organization'}
                />
              </Box>
              <Box sx={webStyle.inputStyle}>
                <TextField
                  id="outlined-basic"
                  onChange={this.handleChange}
                  label={configJSON.contactNumber}
                  variant="outlined"
                  name="contactNumber"
                  color="secondary"
                  value={this.state.contactNumber}
                  data-test-id={'text-contact-number'}
                />
              </Box>
              <Box sx={webStyle.inputStyle}>
                <TextField
                  label={configJSON.birthDate}
                  type="date"
                  value={this.state.selectedDate}
                  name="selectedDate"
                  onChange={this.handleChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  color="secondary"
                  data-test-id={'birth-date'}
                />
              </Box>
              <Box sx={webStyle.inputStyle}>
                <Select
                  labelId="test-select-label"
                  id="demo-simple-select"
                  value={
                    this.state.gender ? configJSON.male : configJSON.female
                  }
                  label={configJSON.gender}
                  onChange={this.handleChangeGender}
                  color="secondary"
                  variant="outlined"
                  data-test-id={'select-gender'}
                >
                  <MenuItem value={'true'}>{configJSON.male}</MenuItem>
                  <MenuItem value={'false'}>{configJSON.female}</MenuItem>
                </Select>
              </Box>
              <Box sx={webStyle.inputStyle}>
                <TextField
                  id="outlined-basic"
                  onChange={this.handleChange}
                  label={configJSON.title}
                  variant="outlined"
                  name="title"
                  color="secondary"
                  value={this.state.title}
                  data-test-id={'text-title'}
                />
              </Box>
              <Box sx={webStyle.inputStyle}>
                <TextField
                  id="outlined-basic"
                  onChange={this.handleChange}
                  label={configJSON.url}
                  variant="outlined"
                  name="websiteUrl"
                  color="secondary"
                  value={this.state.websiteUrl}
                  data-test-id={'text-url'}
                />
              </Box>
              <Box sx={webStyle.inputStyle}>
                <TextField
                  id="outlined-basic"
                  onChange={this.handleChange}
                  label={configJSON.note}
                  variant="outlined"
                  name="note"
                  color="secondary"
                  value={this.state.note}
                  data-test-id={'text-note'}
                />
              </Box>

              <Button
                color={'primary'}
                onClick={this.generateCard}
                data-test-id={'button-generate-vcard'}
                style={webStyle.buttonStyle}
              >
                {configJSON.generateCardButton}
              </Button>
              <Box style={webStyle.uploadBtnWrapper}>
                <Typography style={webStyle.uploadFileLabel}>
                  {configJSON.uploadFileButton}
                </Typography>
                <Input
                  type="file"
                  onChange={this.onFileChange}
                  data-test-id={'input-file'}
                />
              </Box>
            </Box>
          </Container>
        </ThemeProvider>
      </StyledEngineProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: 'flex',
    fontFamily: 'Roboto-Medium',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '20px',
    background: '#fff',
  },
  inputStyle: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '20px',
  },
  buttonStyle: {
    width: '100%',
    height: '45px',
    marginTop: '40px',
    border: 'none',
    backgroundColor: 'rgb(98, 0, 238)',
  },
  textFieldStyles: {
    width: '100%',
  },
  inputFieldFirstName: {
    width: '32%',
    marginRight: '10px',
    paddingBottom: '20px',
  },
  inputFieldMiddleName: {
    width: '32%',
    marginRight: '10px',
  },
  inputFieldLastName: {
    width: '32%',
  },
  uploadBtnWrapper: {
    width: '100%',
    marginTop: '20px',
  },
  uploadFileLabel: {
    display: 'inline',
    marginRight: '20px',
  },
};
// Customizable Area End
