// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Tasks from "../../blocks/tasks/src/Tasks";
import TaskList from "../../blocks/tasks/src/TaskList";
import Task from "../../blocks/tasks/src/Task";
import Polling from "../../blocks/polling/src/Polling";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import AutomaticFormCreation from "../../blocks/automaticformcreation/src/AutomaticFormCreation";
import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import DownloadOptions from "../../blocks/downloadoptions/src/DownloadOptions";
import EventRegistration from "../../blocks/eventregistration/src/EventRegistration";
import ApplePayIntegration from "../../blocks/applepayintegration/src/ApplePayIntegration";
import CallRecording from "../../blocks/call-recording/src/CallRecording";
import AccountTransfer from "../../blocks/accounttransfer/src/AccountTransfer";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import LanguageOptions from "../../blocks/languageoptions/src/LanguageOptions";
import SelfdestructMessaging from "../../blocks/selfdestructmessaging/src/SelfdestructMessaging";
import Repost from "../../blocks/repost/src/Repost";
import Trending from "../../blocks/trending/src/Trending";
import AuctionBidding from "../../blocks/auctionbidding/src/AuctionBidding";
import Hashtags from "../../blocks/hashtags/src/Hashtags";
import InventoryManagement from "../../blocks/inventorymanagement/src/InventoryManagement";
import FeatureSettings from "../../blocks/featuresettings/src/FeatureSettings";
import ContactList from "../../blocks/contactlist/src/ContactList";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import QuestionBank from "../../blocks/questionbank/src/QuestionBank";
import PerformanceTracker from "../../blocks/performancetracker/src/PerformanceTracker";
import PtCoursesScreen from "../../blocks/performancetracker/src/PtCoursesScreen";
import PtTestsForCourseScreen from "../../blocks/performancetracker/src/PtTestsForCourseScreen";
import PtStudentsOnCourseScreen from "../../blocks/performancetracker/src/PtStudentsOnCourseScreen";
import PtTestScoresForStudentScreen from "../../blocks/performancetracker/src/PtTestScoresForStudentScreen";
import PtBasicUserCoursesScreen from "../../blocks/performancetracker/src/PtBasicUserCoursesScreen";
import PtBasicUserAllCoursesScreen from "../../blocks/performancetracker/src/PtBasicUserAllCoursesScreen";
import PtStudentRankingScreen from "../../blocks/performancetracker/src/PtStudentRankingScreen";
import PtStudentInfoScreen from "../../blocks/performancetracker/src/PtStudentInfoScreen";
import VideoTrimmer from "../../blocks/videotrimmer/src/VideoTrimmer";
import Followers from "../../blocks/followers/src/Followers";
import AutomaticCheckoutCalculation from "../../blocks/automaticcheckoutcalculation/src/AutomaticCheckoutCalculation";
import Gamification from "../../blocks/educationalgamification/src/Gamification";
import Pagination from "../../blocks/pagination/src/Pagination";
import CustomerDialpadKeyboard from "../../blocks/customerdialpadkeyboard/src/CustomerDialpadKeyboard";
import LinkShare from "../../blocks/linkshare/src/LinkShare";
import TermsConditions from "../../blocks/termsconditions/src/TermsConditions";
import TermsConditionsDetail from "../../blocks/termsconditions/src/TermsConditionsDetail";
import TermsConditionsUsers from "../../blocks/termsconditions/src/TermsConditionsUsers";
import Upvotedownvote from "../../blocks/upvotedownvote/src/Upvotedownvote";
import Favourites from "../../blocks/favourites/src/Favourites";
import AddFavourites from "../../blocks/favourites/src/AddFavourites";
import PricingEngine from "../../blocks/pricingengine/src/PricingEngine";
import ActivityFeed from "../../blocks/activityfeed/src/ActivityFeed";
import DailyScheduleNotifications from "../../blocks/dailyschedulenotifications/src/DailyScheduleNotifications";
import LanguageSupport from "../../blocks/languagesupport/src/LanguageSupport";
import FacialTracking from "../../blocks/facialtracking/src/FacialTracking";
import SpellCheck from "../../blocks/spellcheck/src/SpellCheck";


const routeMap = {
Tasks:{
 component:Tasks,
path:"/Tasks"},
TaskList:{
 component:TaskList,
path:"/TaskList"},
Task:{
 component:Task,
path:"/Task"},
Polling:{
 component:Polling,
path:"/Polling"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
AutomaticFormCreation:{
 component:AutomaticFormCreation,
path:"/AutomaticFormCreation"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
DownloadOptions:{
 component:DownloadOptions,
path:"/DownloadOptions"},
EventRegistration:{
 component:EventRegistration,
path:"/EventRegistration"},
ApplePayIntegration:{
 component:ApplePayIntegration,
path:"/ApplePayIntegration"},
CallRecording:{
 component:CallRecording,
path:"/CallRecording"},
AccountTransfer:{
 component:AccountTransfer,
path:"/AccountTransfer"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
LanguageOptions:{
 component:LanguageOptions,
path:"/LanguageOptions"},
SelfdestructMessaging:{
 component:SelfdestructMessaging,
path:"/SelfdestructMessaging"},
Repost:{
 component:Repost,
path:"/Repost"},
Trending:{
 component:Trending,
path:"/Trending"},
AuctionBidding:{
 component:AuctionBidding,
path:"/AuctionBidding"},
Hashtags:{
 component:Hashtags,
path:"/Hashtags"},
InventoryManagement:{
 component:InventoryManagement,
path:"/InventoryManagement"},
FeatureSettings:{
 component:FeatureSettings,
path:"/FeatureSettings"},
ContactList:{
 component:ContactList,
path:"/ContactList"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
QuestionBank:{
 component:QuestionBank,
path:"/QuestionBank"},
PerformanceTracker:{
 component:PerformanceTracker,
path:"/PerformanceTracker"},
PtCoursesScreen:{
 component:PtCoursesScreen,
path:"/PtCoursesScreen"},
PtTestsForCourseScreen:{
 component:PtTestsForCourseScreen,
path:"/PtTestsForCourseScreen"},
PtStudentsOnCourseScreen:{
 component:PtStudentsOnCourseScreen,
path:"/PtStudentsOnCourseScreen"},
PtTestScoresForStudentScreen:{
 component:PtTestScoresForStudentScreen,
path:"/PtTestScoresForStudentScreen"},
PtBasicUserCoursesScreen:{
 component:PtBasicUserCoursesScreen,
path:"/PtBasicUserCoursesScreen"},
PtBasicUserAllCoursesScreen:{
 component:PtBasicUserAllCoursesScreen,
path:"/PtBasicUserAllCoursesScreen"},
PtStudentRankingScreen:{
 component:PtStudentRankingScreen,
path:"/PtStudentRankingScreen"},
PtStudentInfoScreen:{
 component:PtStudentInfoScreen,
path:"/PtStudentInfoScreen"},
VideoTrimmer:{
 component:VideoTrimmer,
path:"/VideoTrimmer"},
Followers:{
 component:Followers,
path:"/Followers"},
AutomaticCheckoutCalculation:{
 component:AutomaticCheckoutCalculation,
path:"/AutomaticCheckoutCalculation"},
Gamification:{
 component:Gamification,
path:"/Gamification"},
GroupVideoCall:{
 component:GroupVideoCall,
path:"/GroupVideoCall"},
Pagination:{
 component:Pagination,
path:"/Pagination"},
CustomerDialpadKeyboard:{
 component:CustomerDialpadKeyboard,
path:"/CustomerDialpadKeyboard"},
LinkShare:{
 component:LinkShare,
path:"/LinkShare"},
TermsConditions:{
 component:TermsConditions,
path:"/TermsConditions"},
TermsConditionsDetail:{
 component:TermsConditionsDetail,
path:"/TermsConditionsDetail"},
TermsConditionsUsers:{
 component:TermsConditionsUsers,
path:"/TermsConditionsUsers"},
Upvotedownvote:{
 component:Upvotedownvote,
path:"/Upvotedownvote"},
Favourites:{
 component:Favourites,
path:"/Favourites"},
AddFavourites:{
 component:AddFavourites,
path:"/AddFavourites"},
PricingEngine:{
 component:PricingEngine,
path:"/PricingEngine"},
ActivityFeed:{
 component:ActivityFeed,
path:"/ActivityFeed"},
DailyScheduleNotifications:{
 component:DailyScheduleNotifications,
path:"/DailyScheduleNotifications"},
LanguageSupport:{
 component:LanguageSupport,
path:"/LanguageSupport"},
FacialTracking:{
 component:FacialTracking,
path:"/FacialTracking"},
SpellCheck:{
 component:SpellCheck,
path:"/SpellCheck"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;