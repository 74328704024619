import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  disliked: boolean;
  liked: boolean;
  _upvote: number;
  _downvote: number;
  token: string;
  userId: string;
  postId: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UpvotedownvoteController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  checkVoteStatusApiCallId: string = "";
  downvotePostApiCallId: string = "";
  upvotePostApiCallId: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      disliked: false,
      liked: false,
      _downvote: 0,
      _upvote: 0,
      token: "",
      postId: "",
      userId: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    await super.componentDidMount();
    await this.getToken();
  }

  // Customizable Area End

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);

    // Customizable Area Start
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));

      this.setState({ token: token });
      this.checkVoteStatusHandler(this.state.postId);
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && apiRequestCallId === this.checkVoteStatusApiCallId) {
        if(responseJson && !responseJson.errors) {
          switch (responseJson.status) {
            case "upvote":
              this.setState({
                liked: true,
                disliked: false,
                _upvote: responseJson.total_upvotes ?? 0,
                _downvote: responseJson.total_downvotes ?? 0,
              });
              break;
            case "downvote":
              this.setState({
                liked: false,
                disliked: true,
                _upvote: responseJson.total_upvotes ?? 0,
                _downvote: responseJson.total_downvotes ?? 0,
              });
              break;
            default:
              this.setState({
                liked: false,
                disliked: false,
                _upvote: responseJson.total_upvotes ?? 0,
                _downvote: responseJson.total_downvotes ?? 0,
              });
              break;
          }
        } else {
          this.showAlert(configJSON.errorAlertTitle, responseJson.errors);
        }
      }

      if (apiRequestCallId === this.downvotePostApiCallId) {
        if (responseJson && !responseJson.errors) {
          this.setState({
            liked: false,
            disliked: true,
            _upvote: responseJson.total_upvotes,
            _downvote: responseJson.total_downvotes,
          });
        }

        if (errorResponse !== undefined) {
          this.showAlert(configJSON.errorAlertTitle, configJSON.errorAlertMessage);
        }
      }

      if (apiRequestCallId === this.upvotePostApiCallId) {
        if (responseJson && !responseJson.errors) {
          this.setState({
            liked: true,
            disliked: false,
            _upvote: responseJson.total_upvotes,
            _downvote: responseJson.total_downvotes,
          });
        }

        if (errorResponse !== undefined) {
          this.showAlert(configJSON.errorAlertTitle, configJSON.errorAlertMessage);
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  btnLikeProps = {
    testID: "likeBtn",
    onPress: () => this.upvotePostHandler(this.state.postId, this.state.userId),
  };

  btnDisLikeProps = {
    testID: "disLikeBtn",
    onPress: () => this.downvotePostHandler(this.state.postId, this.state.userId),
  };

  webBtnLikeProps = {
    "data-testid": "likeBtn",
    onClick: () => this.upvotePostHandler(this.state.postId, this.state.userId),
  };

  webBtnDisLikeProps = {
    "data-testid": "disLikeBtn",
    onClick: () => this.downvotePostHandler(this.state.postId, this.state.userId),
  };

  getToken = async () => {
    const message: Message = new Message(
        getName(MessageEnum.SessionRequestMessage)
    );
    this.send(message);
  };

  async upvotePostHandler(postId: string, userId: string): Promise<void> {
    const token = this.state.token;
    const upvotePostMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.upvotePostApiCallId = upvotePostMessage.messageId;

    const header = {
      token: token,
      "Content-Type": configJSON.requestHeaderContentType,
    };

    upvotePostMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    let formData = new FormData();
    formData.append("likeable_id", postId);
    formData.append("like_by_id", userId);

    upvotePostMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    upvotePostMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.upvoteEndpoint
    );

    upvotePostMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.votePostMethod
    );

    runEngine.sendMessage(upvotePostMessage.id, upvotePostMessage);
  }

  async downvotePostHandler(postId: string, userId: string): Promise<void> {
    const token = this.state.token;
    const downvotePostMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.downvotePostApiCallId = downvotePostMessage.messageId;

    const header = {
      token: token,
      "Content-Type": configJSON.requestHeaderContentType,
    };

    downvotePostMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    let formData = new FormData();
    formData.append("dislikeable_id", postId);
    formData.append("dislike_by_id", userId);

    downvotePostMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    downvotePostMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.downvoteEndpoint
    );

    downvotePostMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.votePostMethod
    );

    runEngine.sendMessage(downvotePostMessage.id, downvotePostMessage);
  }

  async checkVoteStatusHandler(postId: string): Promise<void> {
    const token = this.state.token;
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.checkVoteStatusApiCallId = requestMessage.messageId;

    const header = {
      token: token,
      "Content-Type": configJSON.requestHeaderContentType,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.checkVoteStatusEndpoint}?postId=${postId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}
