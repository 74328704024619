import React from "react";
// Customizable Area Start

import {
  Button,
  Input,
  Typography,
  Modal,
  CircularProgress,
  Box,
} from "@mui/material";

// Customizable Area End
import AccountTransferController from "./AccountTransferController";
import { Props } from "./AccountTransferController";

export const config = require("./config");

export default class AccountTransfer extends AccountTransferController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderAccountTransferModal = () => {
    return (
      <Modal
        open={this.state.accountTransferModalOpen}
        onClose={this.handleLogout}
      >
        <Box sx={styles.modalView}>
          <Box style={styles.infoText}>
            <Typography>
              {this.state.isAccountTransfer
                ? config.enterEmailText
                : config.enterProvideCredentialsText}
            </Typography>
          </Box>

          <Box style={styles.input}>
            <Input
              data-test-id="txtInputEmail"
              placeholder={config.emailInputPlaceHolder}
              value={this.state.email}
              onChange={(event) => this.handleEmailChange(event.target.value)}
              onBlur={() => this.validateEmail()}
            />
            {this.state.validationError ? (
              <Typography data-test-id="txtError" style={styles.errorStyle}>
                {this.state.validationError}
              </Typography>
            ) : null}
          </Box>

          <Box>
            <Button
              data-test-id="btnSubmit"
              style={styles.button}
              onClick={
                this.state.isAccountTransfer
                  ? this.handleAccountTransferSubmit
                  : this.handleProvideCredentialsSubmit
              }
            >
              {config.submitButtonTitle}
            </Button>
            <Button
              data-test-id="btnClose"
              style={styles.button}
              onClick={this.handleAccountTransferModalClose}
            >
              {config.closeButtonTitle}
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };

  renderCircularProgress = () => {
    return (
      <Box sx={styles.absoluteCenter}>
        <CircularProgress />
      </Box>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <Box style={styles.container}>
        {this.state.loading && this.renderCircularProgress()}
        <Button onClick={this.handleProvideCredentialsModalOpen}>
          {config.provideCredentials}
        </Button>
        <Button onClick={this.handleAccountTransferModalOpen}>
          {config.accountTransfer}
        </Button>

        {this.renderAccountTransferModal()}
      </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = {
  container: {
    flexGrow: 1,
    padding: 16,
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: 650,
    backgroundColor: "#fff",
  },
  infoText: {
    margin: 10,
  },
  input: {
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  button: {
    cursor: "pointer",
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    color: "#6200EE",
  },
  errorStyle: {
    color: "red",
    textAlign: "center",
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  },
  absoluteCenter: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
  modalDesc: {
    fontSize: 16,
    color: "#808080",
    marginTop: 10,
  },
  modalAction: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    marginTop: 20,
  },
} as const;
// Customizable Area End
