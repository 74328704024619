Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
(exports.noCameraPermission = "You did not give permisssion to use the camera. You can allow camera from your phone's settings."),
  (exports.detectFaces = "Detect Faces"),
  (exports.detectingFaces = "Detecting Faces"),
  (exports.permissionToUseCamera = "Permission to use camera"),
  (exports.permissionMessage = "We need your permission to use your camera"),
  (exports.buttonPositive = "Ok"),
  (exports.buttonNegative = "Cancel"),
  (exports.noCamera = "There is no camera"),
  (exports.flip = "Flip"),
  (exports.leftEyeOpenProbability = "Left Eye Open Probability: "),
  (exports.rightEyeOpenProbability = "Right Eye Open Probability: "),
  (exports.smilingProbability = "Smiling Probability: "),
  (exports.faceId = "Id: "),
  (exports.rollAngle = "Roll Angle: "),
  (exports.yawAngle = "Yaw Angle: "),
  (exports.open = "Open"),
  (exports.closed = "Closed"),
  (exports.smiling = "Smiling"),
  (exports.neutral = "Neutral"),
  (exports.deg = "deg"),
  (exports.age = "Age"),
  (exports.expression = "Expression"),
  (exports.gender = "Gender"),
  (exports.loading = "Loading.."),
  (exports.faceRecognitionWebcam = "Face Recognition Webcam");
exports.alertTitle = "Alert";
exports.alertMessage = "Something went wrong, please try again!";
// Customizable Area End
