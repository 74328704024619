import React, { useState } from "react";

import { Checkbox, Input, TextField } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";

// Customizable Area Start
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { NewEvent } from "../types";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
    secondary: {
      main: "#000000",
    },
  },
});
// Customizable Area End

// Customizable Area Start
export const TextInputField = (props: {
  field_name: string;
  field_type: string;
  handleInputChange: (event: NewEvent) => void;
}) => {
  const [textValue, setTextValue] = useState("");
  return (
    <Input
      defaultValue={textValue}
      value={textValue}
      placeholder={props.field_name ?? ""}
      name={props.field_name?.replace(/[" "]/g, "_") ?? ""}
      fullWidth={true}
      onChange={(event: any) => {
        if (props.field_type == "integer") {
          let inputValue = parseInt(event.target.value);
          if (!isNaN(inputValue)) {
            setTextValue(`${inputValue}`);
            let newEvent = {
              name: event.target.name,
              value: inputValue,
            };
            props.handleInputChange(newEvent);
          } else if (event.target.value.length == 0) {
            setTextValue("");
            let newEvent = {
              name: event.target.name,
              value: undefined,
            };
            props.handleInputChange(newEvent);
          }
        } else if (props.field_type == "float") {
          let value = event.target.value;

          if (!value || value.match(/^\d{1,}(\.\d{0,4})?$/)) {
            setTextValue(`${event.target.value}`);
            let newEvent = {
              name: event.target.name,
              value: parseFloat(value),
            };
            props.handleInputChange(newEvent);
          }

          if (event.target.value.length == 0) {
            setTextValue("");
            let myEvent = {
              name: event.target.name,
              value: undefined,
            };

            props.handleInputChange(myEvent);
          }
        } else {
          setTextValue(event.target.value);
          let newEvent = {
            name: event.target.name,
            value: event.target.value,
          };
          props.handleInputChange(newEvent);
        }
      }}
    />
  );
};

export const DatePickerField = (props: {
  field_name: string;
  handleInputChange: (event: NewEvent) => void;
}) => {
  const [date, setDate] = useState(null);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={MomentUtils}>
          <DatePicker
            data-test-id="txtInputAvailableDate"
            toolbarPlaceholder="DD/MM/YY"
            inputFormat={"DD/MM/YY"}
            value={date}
            onChange={(availableDate: any) => {
              let newEvent = {
                name: props.field_name.replace(/[" "]/g, "_"),
                value: moment(availableDate).format("DD/MM/YY"),
              };

              props.handleInputChange(newEvent);
              setDate(availableDate);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export const TimePickerField = (props: {
  field_name: string;
  handleInputChange: (event: NewEvent) => void;
}) => {
  const [time, setTime] = useState(null);
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapters={MomentUtils}>
          <TimePicker
            data-test-id="txtInputAvaialblefrom"
            inputFormat={"hh:mm A"}
            value={time}
            onChange={(available_from: any) => {
              let newEvent = {
                name: props.field_name.replace(/[" "]/g, "_"),
                value: moment(available_from).format("HH:mm"),
              };
              props.handleInputChange(newEvent);
              setTime(available_from);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export const CheckboxField = (props: {
  field_name: string;
  handleInputChange: (event: NewEvent) => void;
}) => {
  const [isChecked, setChecked] = useState(false);
  return (
    <div
      style={{
        fontSize: "16px",
        marginTop: "25px",
      }}
    >
      <Checkbox
        data-test-id={"btnRememberMe"}
        onClick={() => {
          let newEvent = {
            name: props.field_name.replace(/[" "]/g, "_"),
            value: !isChecked,
          };
          props.handleInputChange(newEvent);
          setChecked(!isChecked);
        }}
        checked={isChecked}
      />
      {props.field_name}
    </div>
  );
};

export default class AutomaticFields {
  static TextInputField = TextInputField;
  static DatePickerField = DatePickerField;
  static TimePickerField = TimePickerField;
  static CheckboxField = CheckboxField;
}

// Customizable Area End
