Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Self Destruct Messaging";
exports.labelBodyText = "SelfdestructMessaging Body";
exports.users = "User List";


exports.btnExampleTitle = "CLICK ME";
exports.allUsersApi = "account_block/accounts.json"
exports.allMessagesApi = "bx_block_selfdestructmessaging/messages.json"
// Customizable Area End