import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Platform } from "react-native";
// Customizable Area Start

interface Contact{
  id: number;
  type: string;
  attributes: {
      first_name: string;
      last_name: string;
      phone_number: string;
      compstr?: string
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  contactlist:Array<Contact>
  contactlist1:Array<Contact>
  text:string[]
  alpha:string[]
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomerDialpadKeyboardController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  contactapicallID: string='';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      contactlist:[],
      contactlist1:[],
      alpha:[],
      text:[]
      
      // Customizable Area Start
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End

  }

  async receive(from: string, message: Message) {

    runEngine.debugLog("Message Recived", message);

    // Customizable Area Start
    /* istanbul ignore else */
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
			
			const apiRequestCallId = message.getData(
				getName(MessageEnum.RestAPIResponceDataMessage)
			);

			const responseJson = message.getData(
				getName(MessageEnum.RestAPIResponceSuccessMessage)
			);

			const errorReponse = message.getData(
				getName(MessageEnum.RestAPIResponceErrorMessage)
			);
      /* istanbul ignore else */
			if(apiRequestCallId === this.contactapicallID){
        this.setState({contactlist1:responseJson.data})

       let list = responseJson.data;
       list.forEach((element: Contact) => {

        element.attributes.compstr =  element.attributes.phone_number+element.attributes.first_name+element.attributes.last_name;
         
       });
       this.setState({contactlist1:list})

          
          this.parseApiCatchErrorResponse(errorReponse);
        }

			
		}
    // Customizable Area End

  }
  // Customizable Area Start
  async componentDidMount() {
    if(Platform.OS === 'web')
    {
      window.addEventListener("focus", async() => {
        this.getcontactapicall();
      });
    }
    else
    { 
      this.props.navigation.addListener("willFocus", async() => {
        this.getcontactapicall();
      });
    }   
  }



onPress = async (value: string, alphatxt: string) => {

  this.setState(({
    text: [...this.state.text, value]
  }));
  if (alphatxt != "") {
    this.setState(({
      alpha: [...this.state.alpha, alphatxt]
    }));
  }

  this.filtercontact();


}
pageredirect =()=>
{
  this.props.navigation.navigate("CreateContact");

}
onPressBackSpace = () => {

  let array = this.state.text;
  let arraytxt = this.state.alpha;
  this.state.text.splice(-1);
  this.state.alpha.splice(-1);
  this.setState({ text: array, alpha: arraytxt })
  this.filtercontact();


}
filtercontact = () => {
  setTimeout(() => {
    let number = this.state.text.join("")
    let alphatxt = this.state.alpha.join("")
    let join2 = number.concat(alphatxt)
    let arrfinal: Contact[] = [];
    for (let x_loop = 0; x_loop < join2.length; x_loop++) {
      let string1 = join2.charAt(x_loop);
      let string2 = this.state.contactlist1.filter((x_list) => x_list.attributes.compstr?.includes(string1.toLowerCase()))
      if (string2.length > 0) {  
        for (let y_loop = 0; y_loop < string2.length; y_loop++) {
          /* istanbul ignore else */
          if(y_loop==0)
          {
            arrfinal.push(string2[0]);
          }
          else if(string2[y_loop].attributes.phone_number!=string2[y_loop-1].attributes.phone_number)
          {
            arrfinal.push(string2[y_loop]);
          }
        }

      }
    }
    
    this.setState({ contactlist: arrfinal.filter((itemss,
      position) => arrfinal.indexOf(itemss) === position) })
  }
    , 50)

}
  getcontactapicall = async () => {    

		const header = {
			token:"eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MjUzLCJleHAiOjE3MTE2MjQ0MTJ9.vcpp4vG8zmtKdasDzJmOU2C3VDbdkGn2gm4LOpWp9mJGfl8JR7uM_u4ioQXQXHfGeVmX9ONRQVQ6GZv5gs2ekw"
		  };

		const enpointUrl: string = "https://fastel-73286-ruby.b73286.dev.eastus.az.svc.builder.cafe/bx_block_dialpad/dialpads";
		this.apiCall({
			setApiCallId: "contactapicallID",
			header,
			method: 'GET',
			endPoint: enpointUrl,
		  });
	}

	apiCall = async (apidata: {
    header: object,
    endPoint: string,
    method: string,
    body?: FormData | string,
    setApiCallId: string
  }) => {
		const {  header, endPoint, method, body } = apidata;

		const requestMessage = new Message(
			getName(MessageEnum.RestAPIRequestMessage)
		);


			this.contactapicallID = requestMessage.messageId;

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestHeaderMessage),
			JSON.stringify(header)
		);
		requestMessage.addData(
			getName(MessageEnum.RestAPIResponceEndPointMessage),
			endPoint
		);

		requestMessage.addData(
			getName(MessageEnum.RestAPIRequestMethodMessage),
			method
		);

     requestMessage.addData(
			getName(MessageEnum.RestAPIRequestBodyMessage),
			body
		);
		runEngine.sendMessage(requestMessage.id, requestMessage);
	}
  // Customizable Area End
  
}
