import { BlockComponent } from "../../../framework/src/BlockComponent";
// Customizable Area Start
import React, { createRef, SyntheticEvent } from "react";
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
import { FileRejection } from "react-dropzone";
const ffmpeg = createFFmpeg();
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedFileUrl: string;
  isFfmpegLoaded: boolean;
  ssArray: Array<Blob>;
  isTrimStarted: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class VideoTrimmerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  videoRef = createRef<HTMLVideoElement>();
  selectedFileBlob: File | undefined = undefined;
  videoDuration: number = 0;
  sliderStartPercentage: number = 0;
  sliderEndPercentage: number = 100;
  ffmpegLoadStarted: boolean = false;
  // Customizable Area End

  constructor(props: Props) {
    super(props);

    this.state = {
      // Customizable Area Start
      selectedFileUrl: "",
      isFfmpegLoaded: false,
      ssArray: [],
      isTrimStarted: false,
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  componentDidMount(): Promise<void> {
    this.loadFfmpeg();
    return Promise.resolve();
  }

  loadFfmpeg = async () => {
    try {
      if (!ffmpeg.isLoaded() && this.ffmpegLoadStarted === false) {
        this.ffmpegLoadStarted = true;
        await ffmpeg.load();
        this.setState({ isFfmpegLoaded: true });
      }
    } catch (error) {
      alert(configJSON.errorOnLoadingFfmpeg);
    }
  };

  selectFile = async (
    acceptedFiles: File[],
    rejectedFiles: FileRejection[]
  ) => {
    if (acceptedFiles.length > 0) {
      let acceptedFileUrl = window.URL.createObjectURL(new Blob(acceptedFiles));
      this.selectedFileBlob = acceptedFiles[0];

      await this.writeSelectedFileToFfmpeg();
      this.setState({
        selectedFileUrl: acceptedFileUrl,
      });
    }

    if (acceptedFiles.length === 0 && rejectedFiles.length > 0) {
      alert(configJSON.rejectedFileSelectionError);
    }
  };

  // handleLoadedData: React.ReactEventHandler<HTMLVideoElement> = async (event) => {
  // handleLoadedData = async (event: Event) => {
  handleLoadedData = async (event: React.SyntheticEvent<HTMLVideoElement>) => {
    const eventTarget = event.target as HTMLVideoElement;
    this.videoDuration = eventTarget.duration;
    await this.takeArrayOfSSFromVideo();
  };

  trimVideo = async () => {
    this.setState({ isTrimStarted: true });

    try {
      let startSecond = (this.sliderStartPercentage * this.videoDuration) / 100;
      let endSecond = (this.sliderEndPercentage * this.videoDuration) / 100;

      let startTimeString = this.convertDurationToString(startSecond);
      let durationString = this.convertDurationToString(
        endSecond - startSecond
      );
      const inputFileName = this.selectedFileBlob?.name || "";
      const outputFileName = inputFileName.replace(
        /(\.[\w\d_-]+)$/i,
        "_trimmed$1"
      ); // adds '_trimmed' before .extension'

      await ffmpeg.run(
        "-ss",
        startTimeString,
        "-i",
        inputFileName,
        "-t",
        durationString,
        "-c",
        "copy",
        outputFileName
      );

      const trimmedFileData = ffmpeg.FS("readFile", outputFileName);
      const resultBlob = new Blob([trimmedFileData.buffer], {
        type: "video/mp4",
      });

      alert(configJSON.trimSuccessOnWeb);
      this.downloadBlob(resultBlob, outputFileName);
    } catch (error) {
      alert(configJSON.trimErrorText);
    } finally {
      this.handleCancel();
    }
  };

  writeSelectedFileToFfmpeg = async () => {
    if (this.selectedFileBlob) {
      ffmpeg.FS(
        "writeFile",
        this.selectedFileBlob.name,
        await fetchFile(this.selectedFileBlob)
      );
    }
  };

  takeArrayOfSSFromVideo = async () => {
    // if configJSON.numberOfSnapShot is 10, this function takes first frame, last frame and 8 inner frames.
    let ssArray = [];

    for (
      let snapshotIndex = 0;
      snapshotIndex < configJSON.numberOfSnapShot;
      snapshotIndex++
    ) {
      let nextSSTime =
        (snapshotIndex * this.videoDuration) /
        (configJSON.numberOfSnapShot - 1);
      if (snapshotIndex === configJSON.numberOfSnapShot - 1) {
        nextSSTime -= 0.1;
      } // taking the last frame is a bit harder. So we are taking 0.1 seconds earlier of the last frame.

      let nextSSTimeString = this.convertDurationToString(nextSSTime);
      if (this.selectedFileBlob) {
        await ffmpeg.run(
          "-ss",
          nextSSTimeString,
          "-i",
          this.selectedFileBlob.name,
          "-frames:v",
          "1",
          "-q:v",
          "2",
          "output.jpg"
        );
      }

      const ssImageFileData = ffmpeg.FS("readFile", "output.jpg");
      const resultBlob = new Blob([ssImageFileData.buffer], {
        type: "image/jpg",
      });
      ssArray.push(resultBlob);
      this.setState({ ssArray });
    }
  };

  handleCancel = () => {
    this.setState({
      selectedFileUrl: "",
      ssArray: [],
      isTrimStarted: false,
    });

    this.ffmpegLoadStarted = false;
    this.sliderEndPercentage = 100;
    this.sliderStartPercentage = 0;
  };

  handleSliderChange = (
    event: any,
    newValue: number | number[],
    activeThumb: number
  ): void => {
    const newSliderPercentages = newValue as number[];
    const newSliderStartPercentage = newSliderPercentages[0];
    const newSliderEndPercentage = newSliderPercentages[1];

    if (this.videoRef.current) {
      this.videoRef.current.pause();
      if (this.sliderEndPercentage !== newSliderEndPercentage) {
        this.videoRef.current.currentTime =
          (newSliderEndPercentage * this.videoDuration) / 100;
        this.sliderEndPercentage = newSliderEndPercentage;
      }
      if (this.sliderStartPercentage !== newSliderStartPercentage) {
        this.videoRef.current.currentTime =
          (newSliderStartPercentage * this.videoDuration) / 100;
        this.sliderStartPercentage = newSliderStartPercentage;
      }
    }
  };

  downloadBlob = (blob: Blob, filename: string) => {
    const objectUrl = URL.createObjectURL(blob);

    // Create a link to download it
    const anchorElement = document.createElement("a");
    anchorElement.href = objectUrl;
    anchorElement.download = filename;
    anchorElement.target = "_blank";
    anchorElement.click();
    anchorElement.remove();
    URL.revokeObjectURL(objectUrl);
  };

  convertDurationToString = (duration: number) => {
    // duration is number of seconds.
    // 90.555111 second is converted to 00:01:30.555
    return new Date(duration * 1000).toISOString().slice(11, 23);
  };
  // Customizable Area End
}
