import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { Alert, Platform } from "react-native";
import { NavigationProp, ParamListBase } from "@react-navigation/native";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: NavigationProp<ParamListBase>;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface repostedContent {
  post: {
    id: number;
    additional_note: string;
    category_id: number;
    created_at: string;
    updated_at: string;
    account_id: number;
    name: string;
    contact_name?: string;
    phone_number?: string;
    email?: string;
    address?: string;
    website?: string;
    post_type: string;
    privacy: boolean;
    activated: boolean;
    parent_id: number;
    is_repost: boolean;
  };
  parent_post: {
    id: number;
    additional_note: string;
    category_id: number;
    created_at: string;
    updated_at: string;
    account_id: number;
    name: string;
    contact_name?: string;
    phone_number?: string;
    email?: string;
    address?: string;
    website?: string;
    post_type: string;
    privacy: boolean;
    activated: boolean;
    parent_id: number;
    is_repost: boolean;
  };
  author_user: string;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isVisibleModal: boolean;

  messageInput: string;
  selectedContent: { Postid: number };
  token: string;
  repostedContent: repostedContent[];
  allPosts: {
    account_id?: number;
    activated?: boolean;
    additional_note?: string;
    address?: string;
    badge?: string;
    badge_url?: string;
    bookmarked?: boolean;
    category?: string;
    category_id?: number;
    comment_count?: number;
    contact_name?: string;
    created_at: string;
    email?: string;
    flagged?: boolean;
    id: number;
    image?: string;
    image_link?: string[];
    is_repost?: boolean;
    name: string;
    parent_id?: number;
    phone_number?: number;
    post_type?: string;
    privacy?: boolean;
    updated_at?: string;
    user?: string;
    website?: string;
  }[];
  // Customizable Area End
}

interface SS {
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export default class RepostController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  sendPostApiCallId: string = "";
  getPostApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      messageInput: "",
      repostedContent: [],
      isVisibleModal: false,
      selectedContent: { Postid: 0 },
      allPosts: [],
      token:
        "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NDEwLCJleHAiOjE4Mzc5NjM3NzMsInRva2VuX3R5cGUiOiJsb2dpbiJ9.emY0xGEz3Kh1fVA2_wxV2I5xjI9Q2zXod6NcQiRGPD23vuT_If3s1D3fbB3vneVmDYTqX4b4MdM5uXqEUurfkg",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId === this.getPostApiCallId) {
        if (responseJson.errors) {
          this.showAlert("Error", configJSON.errorRepostMessage);
        } else {
          this.setState({ allPosts: responseJson });
        }
        this.parseApiCatchErrorResponse(errorResponse);
      }
      if (apiRequestCallId === this.sendPostApiCallId) {
        if (responseJson.errors) {
          this.showAlert("Error", configJSON.errorRepostMessage);
        } else {
          this.setState({ repostedContent: [responseJson] });
          if (Platform.OS === "web") {
            localStorage.setItem(
              "repostedContent",
              JSON.stringify(responseJson)
            );
            this.props.navigation.navigate("RepostList");
          } else {
            this.props.navigation.navigate("RepostList", { responseJson });
          }
        }
        this.parseApiCatchErrorResponse(errorResponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    this.getPosts();
  }

  confirmAlertBox = () =>
    Alert.alert("Repost", "Are you sure to repost", [
      {
        text: "Cancel",
        onPress: () =>
          this.setState({
            isVisibleModal: false,
          }),
      },
      {
        text: "Confirm",
        onPress: () => {
          this.sendPost();
          this.setState({
            isVisibleModal: false,
          });
        },
      },
    ]);

  showRepost = (Postid: number) => {
    this.setState({ isVisibleModal: true, selectedContent: { Postid } });
  };

  toggleModal = () => {
    this.setState({
      isVisibleModal: !this.state.isVisibleModal,
      selectedContent: { Postid: 0 },
    });
  };

  onChange = (text: string) => {
    this.setState({ messageInput: text });
  };

  sendPost = () => {
    let token = this.state.token;
    if (!token || token.length === 0) {
      this.showAlert("Error", "Invaild Token. Plese log in.");
      return false;
    }
    const header = {
      "Content-Type": configJSON.contentRepostApiContentType,
      token: token,
    };
    const httpBody = {
      attributes: {
        is_repost: true,
        parent_id: this.state.selectedContent.Postid,
        additional_note: this.state.messageInput,
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendPostApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendPostEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.sendPostApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getPosts = () => {
    let token = this.state.token;
    if (!token || token.length === 0) {
      this.showAlert("Error", "Invaild Token. Plese log in.");
      return false;
    }
    const header = {
      "Content-Type": configJSON.contentRepostApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getPostApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPostEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleClose = () => this.setState({ isVisibleModal: false });

  HandleConfirm = () => {
    if (confirm("Are You Sure you want to Repost ?")) {
      this.sendPost();
      this.setState({
        isVisibleModal: false,
        messageInput: "",
        selectedContent: { Postid: 0 },
      });
    } else {
      this.setState({
        isVisibleModal: false,
        messageInput: "",
        selectedContent: { Postid: 0 },
      });
    }
  };

  getDateFormate = (date: string) => {
    function padValue(newDate: number) {
      return newDate < 10 ? "0" + newDate : newDate;
    }
    let dateValue = new Date(date);

    return [
      padValue(dateValue.getDate()),
      padValue(dateValue.getMonth() + 1),
      dateValue.getFullYear(),
    ]
      .join("-")
      .concat(
        ` ${dateValue.getHours()}:${dateValue.getMinutes()}:${dateValue.getSeconds()}`
      );
  };
  // Customizable Area End
}
